import * as React from 'react';

import { LandingContainer } from 'containers/landing/landing-container';

const IndexPage: React.FunctionComponent = () => {
    return <LandingContainer />;
};

export default IndexPage;

export { getServerSideProps } from 'lib/get-server-side-props';
